import { Col, Row, Spin } from "antd";
import { Configuration, OpenAIApi } from "openai";
import { useState } from "react";
import cyberSunil from "../../images/cyber-sunil-outline-01.png";

export const CyberSunil = () => {
  let [promptInput, setPromptInput] = useState("");
  let [spinner, setSpinner] = useState(false);
  let [response, setResponse] = useState("response will appper here...");

  const configuration = new Configuration({
    organization: "org-cfioiZSGuILc9KbwIguD39uF",
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  });
  const openai = new OpenAIApi(configuration);

  const handleSubmit = async (e) => {
    setSpinner(true);
    e.preventDefault();
    const response = await openai.createCompletion({
      prompt: promptInput,
      model: "text-davinci-003",
      temperature: 0,
      max_tokens: 1000,
    });
    const message = response.data.choices[0].text;
    setResponse(message);
    setSpinner(false);
  };

  return (
    <Col span={24} style={{ textAlign: "center" }}>
      <Row gutter={[18]}>
        <Col span={24}>
          <img
            alt="outline of cyber sunil"
            src={cyberSunil}
            style={{ height: "150px" }}
          />
        </Col>
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <br></br>
            <div>{response}</div>
            <div style={{ textAlign: "center" }}>
              <Spin spinning={spinner} tip="thinking..." />
            </div>
          </div>
          <br></br>
        </Col>
        <Col span={24}>
          <div className="App">
            <form onSubmit={handleSubmit}>
              <input
                style={{ width: "100%" }}
                name="promptInput"
                value={promptInput}
                onChange={(e) => setPromptInput(e.target.value)}
              />
              <br></br>
              <br></br>
              <button>SUBMIT</button>
            </form>
          </div>
          <p>
            <small>
              {"built by "}
              <a target="_blank" rel="noreferrer" href="https://techforgood.lk">
                {"techForGood.lk"}
              </a>
            </small>
          </p>
        </Col>
      </Row>
    </Col>
  );
};
