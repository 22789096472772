import { Row } from "antd";

import { CyberSunil } from "../components/landingPage/cyberSunil";

export const LandingPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#f4f1ed",
        justifyContent: "center",
        display: "flex",
        minHeight: "100vh",
        height: "100%",
        padding: "10px",
      }}
    >
      <Row style={{ maxWidth: "600px" }}>
        <CyberSunil />
      </Row>
    </div>
  );
};
